import * as React from 'react';
import {
  StyledModal,
  ModalContent,
  CancelButtonContainer,
  OkButtonContainer,
} from './styled';
import Header from '../Header';
import { Form } from '../form-elements/inputs';

type Props = {
  open: boolean,
  children: React.ReactNode,
  onClose: (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void,
  title: string,
  onAfterOpen?: ReactModal.OnAfterOpenCallback,
  breadcrumbs?: string,
  okButtons?: React.ReactNode,
  cancelButtons?: React.ReactNode,
  zIndex?: number,
  loading?: boolean,
  centered?: boolean,
  form?: boolean,
  onSubmit?: Function,
  helpButton?: React.ReactNode,
  icon?: React.ReactNode,
  formKey?: string,
  formId?: string,
  width?: number,
  formStyles?: any
};

class Modal extends React.Component<Props, {}> {
  componentDidMount(): void {
    window.addEventListener('keydown', this.closeOnEscKey, true);
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.closeOnEscKey, true);
  }

  closeOnEscKey = (event: KeyboardEvent): void => {
    const { onClose } = this.props;
    if (event.keyCode === 27) onClose();
  };

  render() {
    const {
      open,
      onClose,
      breadcrumbs,
      title,
      children,
      cancelButtons,
      okButtons,
      zIndex,
      loading,
      centered = false,
      helpButton,
      icon,
      form,
      onSubmit,
      formKey,
      formId,
      onAfterOpen,
      width,
      formStyles,
    } = this.props;
    const Container = form ? Form : 'div';
    return (
      <StyledModal
        centered={centered}
        isOpen={open}
        onRequestClose={onClose}
        style={{
          overlay: {
            zIndex: zIndex === undefined ? 10 : zIndex,
          },
        }}
        className={loading ? 'loading' : ''}
        onAfterOpen={onAfterOpen}
        width={width}
      >
        <Container
          key={formKey}
          style={formStyles || {}}
          id={formId}
          onSubmit={(e) => {
            e.preventDefault();
            if (onSubmit) onSubmit(e);
          }}
        >
          <Header
            title={title}
            defaultBreadcrumb={{ title: breadcrumbs }}
            alwaysUseDefaultBreadcrumb
            icon={icon}
          >
            {helpButton}
          </Header>
          <ModalContent>{children}</ModalContent>
          <CancelButtonContainer>{cancelButtons}</CancelButtonContainer>
          <OkButtonContainer>{okButtons}</OkButtonContainer>
        </Container>
      </StyledModal>
    );
  }
}

export default Modal;
