import React from 'react';
import Elevio from 'elevio/lib/react';

const EXCLUDED_URL_PATTERNS = [/upload-documents/, /appointments\/book/, /journeys\/template/];

function HelpButton() {
  if (EXCLUDED_URL_PATTERNS.filter((pattern) => pattern.test(window.location.href)).length === 0 && process.env.REACT_APP_ELEVIO_ACCOUNT_ID) {
    return (<Elevio accountId={process.env.REACT_APP_ELEVIO_ACCOUNT_ID} />);
  }
  return null;
}

export default HelpButton;
 