import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import 'focus-visible/dist/focus-visible';
import focusWithin from 'focus-within';
import { HistoryMonitor, setPrefix } from './common/Header/return-nav';
import Alerts from './common/Alerts/component';
import Loading from './common/Loading';
import './common/fontawesome';
import './App.css';
import PrivateRoute from './common/auth/Routing';
import ErrorBoundary from './common/ErrorBoundary';
import 'common/fontawesome';
import './App.css';
import HelpButton from 'common/HelpButton';

focusWithin(document);

const Appointments = React.lazy(() => import('./components/Appointments/App'));
const Integrations = React.lazy(() => import('./components/Integrations/App'));
const MoveLeads = React.lazy(() => import('./components/MoveLeads/App'));
const Internal = React.lazy(() => import('./components/Internal/App'));
const Journeys = React.lazy(() => import('./components/Journeys/App'));
const Voice = React.lazy(() => import('./components/Voice/App'));
const UploadDocuments = React.lazy(() => import('./components/UploadDocuments'));
const copyAccount = React.lazy(() => import('./components/CopyAccount'));
const PageNotFound = React.lazy(() => import('./common/PageNotFound'));
const SpfAndDmarcRecords = React.lazy(() => import('./components/SpfDmarcRecords/App'));
const EmailDomainVerification = React.lazy(() => import('./components/EmailDomainVerification/App'));
const DirectDebit = React.lazy(() => import('./components/DirectDebit'));

setPrefix('/v3');

function App(): React.ReactElement {
  return (
    <ErrorBoundary>
      <Router basename="/v3">
        <Alerts />
        <HistoryMonitor />
        <HelpButton />

        <React.Suspense
          fallback={(
            <div className="relative-to-sidebar">
              <Loading />
            </div>
          )}
        >
          <Switch>
            <PrivateRoute path="/integrations" component={Integrations} />
            <PrivateRoute path="/moveLeads" component={MoveLeads} />
            <Route path="/appointments" component={Appointments} />
            <PrivateRoute path="/internal" component={Internal} />
            <PrivateRoute path="/voice" component={Voice} />
            <Route path="/journeys" component={Journeys} />
            <Route path="/upload-documents" component={UploadDocuments} />
            <Route path="/platform/copy-account" component={copyAccount} />
            <Route path="/emailDomains" component={EmailDomainVerification} />
            <Route path="/spfdmarcrecords" component={SpfAndDmarcRecords} />
            <Route path="/direct-debit" component={DirectDebit} />
            <Route path="*" exact component={PageNotFound} />
          </Switch>
        </React.Suspense>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
